/*
--------------------------------------------------------------------------------------
	CLASSES DE COULEURS
--------------------------------------------------------------------------------------
*/


.gradient-bg ( @from, @to ) {
background: -moz-linear-gradient(top,  @from 0%, @to 100%);
background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,@from), color-stop(100%,@to));
background: -webkit-linear-gradient(top,  @from 0%,@to 100%);
background: -o-linear-gradient(top,  @from 0%,@to 100%);
background: -ms-linear-gradient(top,  @from 0%,@to 100%);
background: linear-gradient(to bottom,  @from 0%,@to 100%);
filter: ~'progid:DXImageTransform.Microsoft.gradient(startColorstr="@{from}",endColorstr="@{to}",GradientType=0)';
}



.gradient-bg-80 ( @from, @to ) {
background: -moz-linear-gradient(top,  @from 0%, @to 80%);
background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,@from), color-stop(80%,@to));
background: -webkit-linear-gradient(top,  @from 0%,@to 80%);
background: -o-linear-gradient(top,  @from 0%,@to 80%);
background: -ms-linear-gradient(top,  @from 0%,@to 80%);
background: linear-gradient(to bottom,  @from 0%,@to 80%);
filter: ~'progid:DXImageTransform.Microsoft.gradient(startColorstr="@{from}",endColorstr="@{to}",GradientType=0)';
}

.radial-bg ( @from, @to ) {
background: @from;
background: -moz-radial-gradient(center, ellipse cover,  @from 0%, @to 100%);
background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,@from), color-stop(100%,@to));
background: -webkit-radial-gradient(center, ellipse cover,  @from 0%,@to 100%);
background: -o-radial-gradient(center, ellipse cover,  @from 0%,@to 100%);
background: -ms-radial-gradient(center, ellipse cover,  @from 0%,@to 100%);
background: radial-gradient(ellipse at center,  @from 0%,@to 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='@from', endColorstr='@to',GradientType=1 );
}

.radial-bg-2-47 ( @from, @to ) {
background: @from;
background: -moz-radial-gradient(center, ellipse cover,  @from 2%, @to 47%);
background: -webkit-gradient(radial, center center, 0px, center center, 47%, color-stop(2%,@from), color-stop(47%,@to));
background: -webkit-radial-gradient(center, ellipse cover,  @from 2%,@to 47%);
background: -o-radial-gradient(center, ellipse cover,  @from 2%,@to 47%);
background: -ms-radial-gradient(center, ellipse cover,  @from 2%,@to 47%);
background: radial-gradient(ellipse at center,  @from 2%,@to 47%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='@from', endColorstr='@to',GradientType=1 );
}



.transition ( @what : all, @length : 1s, @easing : ease-in-out ) {  
    -webkit-transition: @what @length @easing;  
       -moz-transition: @what @length @easing;  
         -o-transition: @what @length @easing;  
            transition: @what @length @easing;            
}

.filter ( @property, @value ) {  
    filter: @property;
    -webkit-filter: ~'@{property}(@{value})';
    -moz-filter: ~'@{property}(@{value})';
    -ms-filter: ~'@{property}(@{value})';
    -o-filter: ~'@{property}(@{value})';
    -webkit-filter: ~'@{property}(@{value})';    
}


.box-shadow( @x, @y, @strength, @color ) {
	-webkit-box-shadow: @x @y @strength @color;
	   -moz-box-shadow: @x @y @strength @color;	
			box-shadow: @x @y @strength @color;	
}

.text-shadow( @x, @y, @strength, @color ) {
	-webkit-text-shadow: @x @y @strength @color;
	   -moz-text-shadow: @x @y @strength @color;	
			text-shadow: @x @y @strength @color;	
}

.blanc { color: @blanc !important; }
.blanc-bg {background-color: @blanc !important;}

.noir {color: #000000 !important;}
.noir-bg {background-color: #000000 !important;}

/*.fuschia {color: @fuschia !important;}
.fuschia-bg { background-color: @fuschia !important;}
a.fuschia-bg:hover {background-color:@grisclair !important; color:@blanc !important;}*/


.transparent { background:transparent; }
/*a.transparent:hover { background-color: @grisfonce !important;	color: @blanc !important;}*/

/*.transparent-bleu{ background-color: @bleufonce; opacity:0.9; }*/

.radial-vert{ .radial-bg-2-47(rgba(0, 114, 99, 1), rgba(6, 52, 45,0)); }

.gradient-vert-vertical-full{
background: -moz-linear-gradient(left,  rgba(6,52,45,0) 0%, rgba(5,63,55,0) 9%, rgba(0,113,98,1) 49%, rgba(0,113,98,0) 90%);
background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(6,52,45,0)), color-stop(9%,rgba(5,63,55,0)), color-stop(49%,rgba(0,113,98,1)), color-stop(90%,rgba(0,113,98,0)));
background: -webkit-linear-gradient(left,  rgba(6,52,45,0) 0%,rgba(5,63,55,0) 9%,rgba(0,113,98,1) 49%,rgba(0,113,98,0) 90%);
background: -o-linear-gradient(left,  rgba(6,52,45,0) 0%,rgba(5,63,55,0) 9%,rgba(0,113,98,1) 49%,rgba(0,113,98,0) 90%);
background: -ms-linear-gradient(left,  rgba(6,52,45,0) 0%,rgba(5,63,55,0) 9%,rgba(0,113,98,1) 49%,rgba(0,113,98,0) 90%);
background: linear-gradient(to right,  rgba(6,52,45,0) 0%,rgba(5,63,55,0) 9%,rgba(0,113,98,1) 49%,rgba(0,113,98,0) 90%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0006342d', endColorstr='#00007162',GradientType=1 );
 }

.gradient-vert-vertical{
background: -moz-linear-gradient(left,  rgba(6,52,45,0) 10%, rgba(0,112,97,1) 49%, rgba(0,113,98,0.98) 50%, rgba(6,52,45,0) 85%);
background: -webkit-gradient(linear, left top, right top, color-stop(10%,rgba(6,52,45,0)), color-stop(49%,rgba(0,112,97,1)), color-stop(50%,rgba(0,113,98,0.98)), color-stop(85%,rgba(6,52,45,0)));
background: -webkit-linear-gradient(left,  rgba(6,52,45,0) 10%,rgba(0,112,97,1) 49%,rgba(0,113,98,0.98) 50%,rgba(6,52,45,0) 85%);
background: -o-linear-gradient(left,  rgba(6,52,45,0) 10%,rgba(0,112,97,1) 49%,rgba(0,113,98,0.98) 50%,rgba(6,52,45,0) 85%);
background: -ms-linear-gradient(left,  rgba(6,52,45,0) 10%,rgba(0,112,97,1) 49%,rgba(0,113,98,0.98) 50%,rgba(6,52,45,0) 85%);
background: linear-gradient(to right,  rgba(6,52,45,0) 10%,rgba(0,112,97,1) 49%,rgba(0,113,98,0.98) 50%,rgba(6,52,45,0) 85%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0006342d', endColorstr='#0006342d',GradientType=1 );

}


.ombre_legere {.box-shadow( 0px, 0px, 1px, #000 ); }
.ombre_moyenne {.box-shadow( 0px, 0px, 3px, #000); }
.ombre_forte {.box-shadow( 0px, 0px, 6px, #000); }

.ombretexte_legere { .text-shadow( 0px, 0px, 1px, #000);}
.ombretexte_moyenne { .text-shadow( 0px, 0px, 3px, #000);}
.ombretexte_forte { .text-shadow( 0px, 0px, 6px, #000);}

.ombretexte_moyenne-blanc { .text-shadow( 1px, 0px, 3px, #FFF);}