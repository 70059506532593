/*
--------------------------------------------------------------------------------------
	BACKGROUNDS IMAGES
--------------------------------------------------------------------------------------
*/

@logo : 'logo-caribois.png';

.layoutimage ( @image ) {
	background:url('../../images/@{image}');	
}

.contenuimage ( @image ) {
	background:url('../../images/contenu/@{image}');
	background-size:cover;
	background-position:center;
}


.logo_normal { .layoutimage ( @logo ); }


.sub-background {
width: 100%;
height: 100%;
position: fixed;
z-index: -1;
margin-left: 0px;
}

.sub-background-contenu {
	width:100%;
	height:100%;
	position:absolute;
	z-index:-1;
}