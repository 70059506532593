/*
--------------------------------------------------------------------------------------
	IMPORTS
--------------------------------------------------------------------------------------
*/
@import 'colors.less.branch';
@import 'colorsclass.less.branch';
@import 'typography.less.branch';
@import 'background.less.branch';


/*
--------------------------------------------------------------------------------------
	MISE EN PAGE GENERALE
--------------------------------------------------------------------------------------
*/

.nopadding {
	padding: 0px;
}

.col-centered {
    float: none;
    margin: 0 auto;
}

body {
	font-family: 'Muli', sans-serif;
	background-color : @blanc;
	font-size: @size6;
	color: @vert-caribois;
}

p {
	text-align:	justify;
}

.sub-background-wrapper {
	.gradient-vert-vertical;
	height:100%;
	position:absolute;
	z-index:-1;
}

#header { 

	background-color : @blanc;
	padding:0px;
		
}

@media screen and (min-width:768px) {
	#slogan {
	color: @vert-caribois;
/*	display:table;	*/
	text-align: right;
	padding-top: 20px;
	
		span {
			font-size: @size14;
			line-height: 1.1;
		}
		
	}
}


#header-menu {
	
	/*padding-right: 0px;*/
	
	//margin-top: -20px;
	margin-bottom: 0px;

	 li {
		display: table;
		text-align: center;
		height:60px;
		.bold;
		.uppercase;
		font-family : "Helvetica";
		font-size : @size3;
		color: @blanc;
		border-bottom : 6px solid @blanc;
		z-index: 1;
		background-color: @vert-caribois;
	 }
	 
	 li a {
	 	text-decoration: none;
	 	color : @blanc;
		display: table-cell;
		vertical-align: middle;
		}
	 
	 li.active {
	 	background-color: @vert-clair;
	 }
	 
	 li:hover {
	 	background-color: @vert-intermediaire;
	 }
	 
}


#showcase {

	.image-showcase {
		
		/*
		border-bottom: 2px solid #FFFFFF;
		*/
		
		.caption {
			position: absolute;
			bottom: 0px;
			width: 100%;
			z-index: 1;
		}
		
		.bigtext {
			.bold;
			font-size: @size6;
			padding: 0px 0px 15px 25px;
			color: @blanc;
			.uppercase;
			.ombretexte_forte;
		}
	
	}
	
	


}
	
body #main {

	color : @vert-caribois;
	font-family : "Helvetica";
	font-size : @size2;
	
	a {
	text-decoration:none;
	color: @vert-fonce;
	}
}
		

.trombinoscope {

	float: none;
	margin: 0 auto;
	width: 70%;
	padding-top:15px;
	

	img.equipe {
	
		float:left;
		padding: 15px 20px;
		width: 28%;	
		
	}
}

#bottom {

	text-align: center;
	font-size: @size6;
	.adresse {
		text-align: center;
	}
	
	.phone {
		font-size: @size11;
	}
}

#bottom .inner {
	padding-left: 0px;
	padding-right: 0px;
	overflow:hidden;
}



#bottom .sub-background-contenu {
	height: 130px;
	background: @blanc;
	margin-top: 16px;
}


#bottom img {
}

#footer {
	background-color: @vert-caribois;
	text-transform : uppercase;
	font-family : "Helvetica";
	font-size : @size1;
	color: @blanc;
	padding-bottom: 14px;
	margin-top: 15px;
	
	#footer-menu {
	 
	 margin: 4px 0px 4px;
	 
	 li { display: inline-block; }
	 li + li:before { content : '| '; }
	 li a { color : @blanc; }
	 
	}
}

.img-logo {
	display: initial;
}

.readmore {
	.uppercase;
	.bold;
	background-color: @vert-fade;
	width: 103px;
	padding: 4px;
	margin-top: 10px;
	cursor: pointer;
	
	&:hover {
	background-color: @vert-clair;
	}
}




/*
--------------------------------------------------------------------------------------
	TEXTES
--------------------------------------------------------------------------------------
*/

.bold {	font-weight:bold; }
.uppercase { text-transform: uppercase; }

/*
--------------------------------------------------------------------------------------
	MODAL : TOUS
--------------------------------------------------------------------------------------
*/
.modal {
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;	
}

.modal h3 {
	font-size:14px;
	line-height:14px;
	height:30px;
	margin-top:5%;
	display:inline;
}

.modal-content {
	border-radius: 0px !important;
}

.modal-header {

	/*background-color : @grisdark;*/
	border-bottom: 0px solid #e5e5e5;
	
	h4 {
	color: @vert-intermediaire;
	width: 80%;
	padding: 4px 0px 0px 20px;
	}
	
	button.close {
	padding: 0px 10px;
	font-size: @size5*2;
	}

}

.modal-body {

	/*font-family: @source, sans-serif;*/
	font-size: 14px;
	color: @vert-intermediaire;
	
	.modal-body input[type=text] {
	font-size: 18px;
	}
}

.modal-footer {

}

.modal-md {
	width: 700px !important;
	
	.modal-body {
	padding-top: 0px !important;
	}
	
	.modal-header {
	padding: 0px !important;
	}
	
	
}


/*
--------------------------------------------------------------------------------------
	ELEMENTS FORMULAIRES
--------------------------------------------------------------------------------------
*/
input, textarea, button, a.btn {
	border-radius:0px !important;
	margin:2px 0px 10px!important;
	
	transition:all 0.5s ease-out;
	-webkit-transition:all 0.5s ease-out;
	-moz-transition:all 0.5s ease-out;
	-o-transition:all 0.5s ease-out;
}

input[type="button"].fuschia-bg:hover {
	background-color:#666 !important;
	color: @blanc !important;
}


input[type="button"].gris-bg:hover {
	/*background-color: @grisfonce !important;*/
	color: @blanc !important;
}

input[type="button"].btn:hover {
	background-color:#333 !important;
	color: @blanc !important;
}

input[type="text"].special-bottom {
	background: -moz-linear-gradient(top,  rgba(216,216,216,0.65) 0%, rgba(0,0,0,0) 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(216,216,216,0.65)), color-stop(100%,rgba(0,0,0,0)));
	background: -webkit-linear-gradient(top,  rgba(216,216,216,0.65) 0%,rgba(0,0,0,0) 100%);
	background: -o-linear-gradient(top,  rgba(216,216,216,0.65) 0%,rgba(0,0,0,0) 100%);
	background: -ms-linear-gradient(top,  rgba(216,216,216,0.65) 0%,rgba(0,0,0,0) 100%);
	background: linear-gradient(to bottom,  rgba(216,216,216,0.65) 0%,rgba(0,0,0,0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6d8d8d8', endColorstr='#00000000',GradientType=0 );

	border-width:0 0 2px 0;
}


.input-md {
	height: 25px;
	padding: 10px 16px;
	font-size: 18px;
	line-height: 1.33;
	border-radius: 6px;
}



/*
--------------------------------------------------------------------------------------
	PAGE ACCUEIL
--------------------------------------------------------------------------------------
*/


#offre-speciale {
	background-color: @vert-caribois;
	text-align: center;
	color: @blanc;
	
	@media (max-width: 768px) {
		div.texte-offre span{
			font-size: 16px !important;
		}
		div.texte-offre {
			margin-top: 5px !important;
		}
	}
}

/*
--------------------------------------------------------------------------------------
	PAGE GAMME
--------------------------------------------------------------------------------------
*/

// 5 COLUMNS LAYOUT CLASSES

.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}

.col-xs-15 {
    width: 20%;
    float: left;
}
@media (min-width: 768px) {
	.col-sm-15 {
        width: 20%;
        float: left;
    }
}
@media (min-width: 992px) {
    .col-md-15 {
        width: 20%;
        float: left;
    }
}
@media (min-width: 1200px) {
    .col-lg-15 {
        width: 20%;
        float: left;
    }
}

// 5 COLUMNS LAYOUT CLASSES   END

.gamme-showcase {
	
	color: @vert-clair;
	padding-left: 4px;
	padding-right: 0px;
	
	border-bottom : 2px solid @blanc;
	
	
		
	
	.gamme-soustitre {
		
		margin-bottom : 5px;
		
		a {
		color: @vert-clair;
		text-decoration: none;
		.uppercase;
		}
	}

}
	.box-gamme {
	
		/*padding: 10px;
		width: 20%;*/
		float:left;
		cursor:pointer;
		background-color: #007c6e;
		color: #fff;
		/*&:nth-child(5), &:nth-child(10) {
		padding-right:0px;
		}*/
		
		.caption {
			padding: 5px;
			font-size: 16px;
			font-weight: bold;
		}
		
	}
	/*
	.box1 {
		margin-left: 0px;
		margin-right: 5px;
	}
	.box2 {
		margin-left: 5px;
		margin-right: 5px;
	}
	.box3 {
		@media (min-width: 768px) {
		margin-left: 5px;
		margin-right: 5px;}
		@media (max-width: 768px) {
			margin-right: 0px;
		}
	}
	.box4 {
		@media (min-width: 768px) {
		.box2;}
		@media (max-width: 768px) {
			.box1;
		}
	}
	.box5 {
		@media (min-width: 768px) {
		margin-left: 5px;
		margin-right: 0px;}
		@media (max-width: 768px) {
			.box2;
		}
	}
	.box6 {
		@media (min-width: 768px) {
		.box1;}
		@media (max-width: 768px) {
			margin-left: 5px;
			margin-right: 0px;
		}
	}
	.box7 {
		@media (min-width: 768px) {
		.box2;}
		@media (max-width: 768px) {
			.box1;
		}
	}
	.box8 {
		.box2;
	}
	.box9 {
		@media (min-width: 768px) {
		.box2;}
		@media (max-width: 768px) {
			margin-left: 5px;
			margin-right: 0px;
		}
	}
	.box10 {
		@media (min-width: 768px) {
		margin-left: 5px;
		margin-right: 0px;}
		@media (max-width: 768px) {
			.box2;
		}
	}*/
	.box1, .box2, .box3, .box4, .box5, .box6, .box7, .box8, .box9, .box10 {margin:5px;}
	
	@media (min-width: 879px) {
		.col-sm-offset-4 {
			margin-left: 0px;
		}
	}

	.ombre-verticale {
		background: url('../images/ombre-verticale.png') repeat-x;
		height: 15px;
	}


	#pop_main_img {
	padding : 0px !important;
	}
	
	#txt, #titre_home, #surface_home {
	color : #333333;
	 font-size: 18px;
	}


	#link_prec .glyphicon, #link_next .glyphicon {
		font-size: 20px;
	}
	
	#txt { color: #333333; font-size: 18px; }



/*
--------------------------------------------------------------------------------------
	PAGE OPPORTUNITES
--------------------------------------------------------------------------------------
*/

	.villa-listing div {
	
		/* height: 150px; */
		padding-left: 15px !important;
		padding-right: 15px;
		padding-top: 0px !important;
	
		margin-bottom: 10px;
		.town {
		font-size : @size5;
		.uppercase;
		.bold;
		}
		
		.price {
		 font-size : @size5;
		 .bold;
		 float: right;
		 
			.euro {
			font-weight: normal;
			}
		 
		}
		
		.sautdeligne {
			background-color: white;
		}
	
	}

	.box {
		background-color: @vert-caribois;
		color: white;
	}
		
@media (min-width: 992px)	 {
	.villa-listing div {
	/*	height: 150px; */
	}
}

@media (min-width: 768px)	 {
	.villa-listing div {
	/*	height: 200px; */
	}
}

@media (min-width: 768px)	 {
	.rang2, .rang4, .rang6 {
		border-left: white solid 10px;
	}
}

/*
--------------------------------------------------------------------------------------
	PAGE CONTACTS
--------------------------------------------------------------------------------------
*/	
	#formulaire-contact {
	
		label { font-size: 17px; font-weight: bold; text-align: left; }
		input[type=text], textarea { color: @vert-caribois; border: solid thin @vert-caribois; background-color: white; }
		textarea { margin: 0px 0px 0px 15px !important; }
	}

	.input-sm {
		height: auto;
	}

input[type=submit] {
    background-color: @vert-caribois;
    color: white;
    cursor: pointer;
    padding: 3px 10px;
    border: none;
}

/*
--------------------------------------------------------------------------------------
	MOBILE SETTINGS
--------------------------------------------------------------------------------------
*/

@media screen and (min-width:879px) {

	#header #slogan h3 {
		font-size: 24px;
	}
	
	.col-md-12 {
		padding-left: 0px;
		padding-right: 0px;	
	}

	.basicslider {
		margin-left: 0px !important;
		margin-right: 0px !important;
	}

	.readmore {
		width: 113px;
	}

}



@media screen and (min-width:1200px) {

	.wrapper, .wrapper-head {
		width: 1200px;
	}

	#header #slogan h3 {
		font-size: 34px;
	}

}


/*
--------------------------------------------------------------------------------------
	MOBILE SETTINGS
--------------------------------------------------------------------------------------
*/

@media screen and (min-width:768px) {

}

@media screen and (max-width:768px) {
	
	body.mobile {
	overflow:hidden;
	}
	
	#header {
		padding-top: 2px;
		height: 40px;
		//position: fixed;
		width: 100%;
		z-index:3;
	}
	
	#logo {
		padding : 3px;
	/*	position: absolute;	*/
		z-index: 2;
		top: 0px;
		left: 5px;
	}
	
	#slogan {
	color: @vert-caribois;
/*	display:table;	*/
	text-align: right;
	padding-top: 20px;
	
		span {
			font-size: @size8;
			line-height: 1.1;
		}
		
	}
	
	#retour-accueil {
	margin-top : 35px;
	background-color: @blanc;
	padding:4px;
	margin-right: -25px;
		a {
		width:100%;
		height: 100%;
		text-decoration: none;
		.bold;
		color: #333333;
		}
	}
	
	
	#main {
		
		height:100%;
		color : @blanc;
		
		div[id^=main-] {
		float:left;
		padding : 115px 15px !important;
		background-size: cover;
		background-position: center center;
		height:100%;
		}
		
		#main-accueil {
		background-image:url('../images/mobile-background/accueil.jpg');
		}
		#main-gamme {
		background-image:url('../images/mobile-background/gamme.jpg');
		}
		#main-garanties {
		background-image:url('../images/mobile-background/garanties.jpg');
		}
		#main-savoir-faire {
		background-image:url('../images/mobile-background/savoir-faire.jpg');
		}
		#main-opportunites {
		background-image:url('../images/mobile-background/opportunites.jpg');
		}
		#main-contact {
		background-image:url('../images/mobile-background/accueil.jpg');
		}
	}
	

	.readmore {
		width:100%;
	}

#header-menu {
	
	//margin-top: -20px;
	margin-bottom: 0px;
	padding-right: 20px;

	ul {
		padding: 0px;
		-webkit-padding-start: 0px;
	}
	
	 li {
		
		border-bottom : none;
		/* .opacite-moyenne; */ 
		margin-bottom: 5px;
		
		.sub-background {
			background-color: @blanc;
			opacity:0.7;
			height:100%;
			position:absolute;
			z-index:-1;
			left: 0px;
		}
		
		.glyphicon {
			background-color : @vert-fonce;
			height: 41px;
			width: 42px;
			padding-top: 11px;
			margin-top: 7px;
			font-size: @size6;
		}
		
	 }
	 
	 li a {
	 	//font-size : @size5;
	 	font-size: 13px;
	 	.bold;
	 	text-decoration: none;
	 	color: white;
		display: table-cell;
		vertical-align: middle;
		width: 200px;
		text-align:left;
		}
	 
	 li.active {
	 	background-color: @vert-clair;
	 }
	 
	 li:hover {
	 	background-color: @vert-intermediaire;
	 }
	 
}	
	

}
