/*
--------------------------------------------------------------------------------------
	IMPORTS TYPOGRAPHY
--------------------------------------------------------------------------------------
*/

@import url('https://fonts.googleapis.com/css?family=Muli:400,800,900');
/*
@import url(http://fonts.googleapis.com/css?family=Oxygen:400,300);
@import url(http://fonts.googleapis.com/css?family=Merriweather+Sans:400,300);
@import url(http://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400);
@import url(http://fonts.googleapis.com/css?family=Seaweed+Script);
@import url(http://fonts.googleapis.com/css?family=Alex+Brush);


@oxygen			: 'Oxygen';
@merriweather	: 'Merriweather Sans';
@source			: 'Source Sans Pro';
@seaweed		: 'Seaweed Script';
@alex			: 'Alex Brush';
*/

@size1 : 12px;
@size2 : @size1 + 2px;
@size3 : @size1 + 3px;
@size4 : @size1 + 4px;
@size5 : @size1 + 6px;
@size6 : @size1 + 8px;
@size7 : @size1 + 10px;
@size8 : @size1 + 12px;
@size9 : @size1 + 14px;
@size10 : @size1 + 16px;
@size11 : @size1 + 18px;
@size12 : @size1 + 20px;
@size13 : @size1 + 22px;
@size14 : @size1 + 26px;

h1 {
	font-size: @size9;
}


h2 {
	font-size: @size7;
}
